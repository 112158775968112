/* You can add global styles to this file, and also import other style files */
body {
    font-family: 'Montserrat', sans-serif;
    background: #0E0D12;
    font-size: 14px;
    padding: 0px;
    margin: 0px;
}

::placeholder {
    line-height: normal;
}

p {
    color: #999;
}
* {
    box-sizing: border-box;
}
ul {
    margin: 0px;
    padding: 0px;
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}
input {
    outline: none;
    font-family: 'Montserrat', sans-serif;
}
.page-margin-top a {
    color: #008FD5
}

p {
    margin-top: 1rem;
}

h1,
h2,
h3,
h4 {
    color: #ffffff
}

.fullPageBG {
    background: url('/assets/images/webBG.png') no-repeat center center;
    background-size: cover;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    flex-direction: column;
}
.fullPageBG label {
    font-size: 20px;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 10px;
}
.fullPageBG h1 {
    font-size: 30px;
    margin: 0px;
    margin-bottom: 10px;
}
.fullPageBG .boxesDesign{
    display: flex;
    flex-flow: wrap;
    /* align-items: center; */
    justify-content: center;
    /* margin: 10px; */
    gap: 10px;
    padding: 20px;
    max-width: 1024px;
    /* margin: auto;*/
}

.fullPageBG .boxesDesign .bxListing {
    padding: 10px;
    background: linear-gradient(#000 0%, #191818 49.75%, #2c2c2c 100%);
    flex-direction: column;
    display: flex;
    line-height: 38px;
    align-items: center;
    font-size: 16px;
    gap: 9px;
    justify-content: center;
    color: #fff;
    text-align: center;
    width: 280px;
    border: 1px solid #cccccc52;
    cursor: pointer;
}
.fullPageBG .flSeachHead {
    max-width: 320px;
    width: 100%;
}
.fullPageBG .flSeachHead label{
    font-size: 20px;
    margin-bottom: 14px;
    display: block;
}
.fullPageBG .flSeachHead .searchBox {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.fullPageBG .flSeachHead .searchBox .textbox{
    border: 0px;
    font-size: 16px;
    line-height: 33px;
    padding: 4px 10px;
    width: 100%;
    border: 1px solid #fff;
}
.fullPageBG .flSeachHead .searchBox .btn {
    border: 0px;
    font-size: 16px;
    line-height: 33px;
    padding: 4px 10px;
    text-transform: uppercase;
    border: 1px solid #cccccc52;
    background: linear-gradient(#000 0%, #191818 49.75%, #2c2c2c 100%);
    color: #fff;
    cursor: pointer;
}
.fullPageBG  .notfound {
    text-transform: uppercase;
    font-size: 20px;
    padding: 20px;
    color:rgb(129, 120, 120);
    background-color: rgba(0,0,0.5);
}

.fullPageBG .draft-king-logo {
    position: absolute;
    top: 10px;
    left: 0px;
}
.fullPageBG .draft-king-logo img {
    width: 150px;
}
.fullPageBG .brandLogo {
    max-width: 220px;
}

@media (max-width:767px) {
    .launchPage.fullPageBG {
        padding-bottom: 50px;
        overflow: auto;
        background-attachment: fixed;
        justify-content: flex-start;
        padding-top: 40px;
    } 
    .launchPage .draft-king-logo {
        position: unset;
        padding: 10px 20px;
        top: unset;
        bottom: 20px;
        left: 0px;
        width:100%;
        right: 0px;
        margin: auto;
        text-align: center;
        order: 5;
    }
    .launchPage {
        padding: 15px;
        text-align: center;
    }
   
}